import * as Yup from 'yup';
import { omit } from 'lodash';

import {
  ApplicationFormFieldsConfig,
  ApplicationFormFieldsSchema,
} from './ApplicationFormModal.types';

export const getResultApplicationFormValues = (
  values: ApplicationFormFieldsSchema,
  fieldsConfig?: ApplicationFormFieldsConfig
) => {
  const paths: Array<keyof ApplicationFormFieldsSchema> = [];

  if (!fieldsConfig?.dealers?.length) {
    paths.push('dealer');

    if (values.dealer && values.dealer.value) {
      const crmId =
        fieldsConfig?.dealers?.find(
          (item) => item.value === values.dealer.value
        )?.crmId || null;
      values.dealer.value = crmId ? String(crmId) : '';
    }
  }

  if (!fieldsConfig?.models?.length) {
    paths.push('model');
  }

  return omit(values, paths) as ApplicationFormFieldsSchema;
};

export const initialApplicationFormValues: ApplicationFormFieldsSchema = {
  name: '',
  phone: '',
  dealer: { label: '', value: '' },
  model: { label: '', value: '' },
  message: '',
};

export const validationApplicationSchema = (
  fieldsConfig?: ApplicationFormFieldsConfig
): Yup.SchemaOf<ApplicationFormFieldsSchema> => {
  return Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    model: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when([], {
      is: () => fieldsConfig?.models && fieldsConfig.models.length !== 0,
      then: Yup.object({
        label: Yup.string().required('Выберите марку автомобиля'),
        value: Yup.string().required('Выберите марку автомобиля'),
      }),
      otherwise: Yup.object({
        label: Yup.string().notRequired(),
        value: Yup.string().notRequired(),
      }),
    }),
    dealer: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when([], {
      is: () => fieldsConfig?.dealers && fieldsConfig.dealers.length !== 0,
      then: Yup.object({
        label: Yup.string().required('Выберите автоцентр'),
        value: Yup.string().required('Выберите автоцентр'),
      }),
      otherwise: Yup.object({
        label: Yup.string().notRequired(),
        value: Yup.string().notRequired(),
      }),
    }),
    message: Yup.string(),
  }) as Yup.SchemaOf<ApplicationFormFieldsSchema>;
};
